import APIService from '@/services/api-service'
const resource = '/job'

class JobService extends APIService {
  public constructor () {
    super()
    this.mountAuthorizationToken()
  }

  public getJobSchedule () {
    return this.connector.get(`${resource}/schedule-jobs`)
  }

  public getBargeJobs (bargeId: any) {
    return this.connector.get(`${resource}/board/barge-jobs/${bargeId}`)
  }

  public getSLPAJobs (officerId: any) {
    return this.connector.get(`${resource}/board/slpa-jobs/${officerId}`)
  }

  public getScheduledJobs () {
    return this.connector.get(`${resource}/board/scheduled-jobs`)
  }

  public getJobLetter (id: any) {
    return this.connector.get(`${resource}/letter/${id}`)
  }
}

export default new JobService()
