
import {
  Component,
  Watch,
  Vue
} from 'vue-property-decorator'
import CollectionService from '../../services/collection-service'
import JobService from '../../services/job-service'
import UomJobs from '@/data/uom-jobs.json'
import {
  mask
} from 'vue-the-mask'
import {
  JobModule
} from '../../store/JobModule'
import {
  AuthModule
} from '@/store/modules/AuthModule'
import {
  Validations
} from 'vuelidate-property-decorators'
import {
  Vuelidate
} from 'vuelidate'
import {
  ToastModule
} from '@/store/modules/ToastModule'
import {
  NotifyModule
} from '@/store/modules/NotifyModule'
import {
  Printd
} from 'printd'
import ActivityService from '../../services/activity-service'

  @Component({
    name: 'JobTable',
    directives: {
      mask
    }
  })
export default class JobTable extends Vue {
    protected isLoader = false
    protected isLoaderTwo = false
    protected isCreated = false
    protected isPrint = false
    protected uoms = UomJobs
    protected scheduledJobs: any = []
    protected scheduledJob: any = {}
    protected tripNumbers: any = []
    protected noOfTrips: any = null
    protected tripNumber: any = null
    protected jobNumber: any = null
    protected vessel: any = null
    protected document: any = null
    protected filteredBarges: any = []
    public barge: any = {}
    protected styles = ['https://api.smc-spl-application.com/print.css']

    protected activity: any = {
      id: null,
      job_id: null,
      barge_id: null,
      barge_name: null,
      barge_code: null,
      trip_number: null,
      engine_start_date: null,
      engine_start_time: null,
      depart_from_pier_date: null,
      depart_from_pier_time: null,
      alongside_vessel_date: null,
      alongside_vessel_time: null,
      horse_connected_date: null,
      horse_connected_time: null,
      commence_loading_date: null,
      commence_loading_time: null,
      completed_loading_date: null,
      completed_loading_time: null,
      horse_disconnected_date: null,
      horse_disconnected_time: null,
      depart_from_vessel_date: null,
      depart_from_vessel_time: null,
      arrival_at_pier_date: null,
      arrival_at_pier_time: null,
      lines: [],
      created_user: null,
      modified_user: null
    }

    $v: Vuelidate

    @Validations()
    validations = {
      activity: {
        engine_start_time: {
          validateTime (value: any) {
            if (value === null) return true
            const isValid = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(value)
            if (isValid) return true
            return false
          }
        },
        depart_from_pier_time: {
          validateTime (value: any) {
            if (value === null) return true
            const isValid = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(value)
            if (isValid) return true
            return false
          }
        },
        alongside_vessel_time: {
          validateTime (value: any) {
            if (value === null) return true
            const isValid = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(value)
            if (isValid) return true
            return false
          }
        },
        horse_connected_time: {
          validateTime (value: any) {
            if (value === null) return true
            const isValid = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(value)
            if (isValid) return true
            return false
          }
        },
        commence_loading_time: {
          validateTime (value: any) {
            if (value === null) return true
            const isValid = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(value)
            if (isValid) return true
            return false
          }
        },
        completed_loading_time: {
          validateTime (value: any) {
            if (value === null) return true
            const isValid = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(value)
            if (isValid) return true
            return false
          }
        },
        horse_disconnected_time: {
          validateTime (value: any) {
            if (value === null) return true
            const isValid = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(value)
            if (isValid) return true
            return false
          }
        },
        depart_from_vessel_time: {
          validateTime (value: any) {
            if (value === null) return true
            const isValid = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(value)
            if (isValid) return true
            return false
          }
        },
        arrival_at_pier_time: {
          validateTime (value: any) {
            if (value === null) return true
            const isValid = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(value)
            if (isValid) return true
            return false
          }
        }
      }
    }

    get job () {
      return JobModule.job
    }

    @Watch('noOfTrips', {
      deep: true
    })
    setTripNumbers () {
      this.tripNumbers = Array.from({
        length: this.noOfTrips
      }, (_, i) => i + 1)
    }

    created () {
      this.boot()
    }

    protected boot () {
      this.document = new Printd()
      this.populateScheduledJobs()
    }

    protected populateScheduledJobs () {
      this.isLoader = true
      JobService.getScheduledJobs().then((response) => {
        this.isLoader = false
        response.data.forEach(job => {
          this.scheduledJobs.push({
            value: {
              id: job.id,
              job_number: job.job_number,
              vessel_name: job.vessel_name,
              lines: job.lines
            },
            text: job.vessel_name
          })
        })
      })
    }

    protected populateNoOfTrips (scheduledJob) {
      this.isLoaderTwo = true
      this.vessel = scheduledJob.vessel_name
      this.jobNumber = scheduledJob.job_number
      this.noOfTrips = null
      this.tripNumber = null
      this.reset(this.activity)
      CollectionService.getNoOfTrips(scheduledJob.id, AuthModule.party.id).then((response) => {
        this.isLoaderTwo = false
        this.noOfTrips = response.data.no_of_trips
      })
    }

    public populateBargeActivity (tripNumber: number) {
      this.isLoaderTwo = true
      this.reset(this.activity)
      ActivityService.getBargeActivity(this.scheduledJob.id, AuthModule.party.id, tripNumber).then((response) => {
        this.isLoaderTwo = false
        if (response.data.message === 'EMPTY') {
          this.isCreated = false
          this.activity.lines = this.scheduledJob.lines.map(line => ({
            service_id: line.service_id,
            service_type: line.service_type,
            quantity: 0,
            old_quantity: 0,
            unit_of_measurement: null,
            created_user: AuthModule.user.fullname,
            modified_user: AuthModule.user.fullname
          }))
          return false
        }
        this.isCreated = true
        this.activity = response.data
        this.activity.lines = response.data.lines.map(line => ({
          id: line.id,
          service_id: line.service_id,
          service_type: line.service_type,
          quantity: line.quantity,
          old_quantity: line.quantity,
          unit_of_measurement: line.unit_of_measurement,
          created_user: line.created_user,
          modified_user: line.modified_user
        }))
      })
    }

    protected isValidated () {
      this.$v.$touch()

      if (this.tripNumber === null) {
        NotifyModule.set('Trip number is empty, Please select...')
        return false
      }

      if (this.$v.$invalid) {
        NotifyModule.set('There are fields that require your attention')
        return false
      }

      return true
    }

    protected save () {
      if (!this.isValidated()) return false

      const activity = this.activity
      if (!this.isCreated) {
        activity.job_id = this.scheduledJob.id
        activity.barge_id = AuthModule.party.id
        activity.barge_name = AuthModule.user.fullname
        activity.trip_number = this.tripNumber
        activity.created_user = AuthModule.user.fullname
      }
      activity.is_complete = false
      activity.modified_user = AuthModule.user.fullname

      const lines = this.activity.lines
      delete activity.lines

      this.isLoaderTwo = true
      ActivityService.saveBargeActivity(activity, lines).then((response) => {
        this.isLoaderTwo = false
        this.$v.$reset()
        this.populateBargeActivity(this.tripNumber)
        ToastModule.message(response.data.message)
        delete activity.lines
      }).catch(error => {
        console.log(error)
      })
    }

    /* protected createBargeActivity () {
      if (!this.isValidated()) return false

      const activity = this.activity
      activity.job_id = this.scheduledJob.id
      activity.is_complete = false
      activity.barge_id = AuthModule.party.id
      activity.barge_name = AuthModule.user.fullname
      activity.trip_number = this.tripNumber
      activity.created_user = AuthModule.user.fullname
      activity.modified_user = AuthModule.user.fullname

      const lines = this.activity.lines

      this.isLoaderTwo = true
      ActivityService.createBargeActivity(activity, lines).then((response) => {
        this.isLoaderTwo = false
        this.$v.$reset()
        this.populateBargeActivity(this.tripNumber)
        ToastModule.message(response.data.message)
        delete activity.lines
      }).catch(error => {
        console.log(error)
      })
    }

    protected updateBargeActivity () {
      if (!this.isValidated()) return false

      const activity = this.activity
      activity.is_complete = false
      const lines = this.activity.lines

      this.isLoaderTwo = true
      ActivityService.updateBargeActivity(activity, lines).then((response) => {
        ToastModule.message(response.data.message)
        this.isLoaderTwo = false
        this.$v.$reset()
        this.populateBargeActivity(this.tripNumber)
        delete activity.lines
      }).catch(error => {
        console.log(error)
      })
    } */

    public print () {
      this.isPrint = true
      this.$nextTick(() => {
        this.document.print(document.getElementById('printWorkDoneCertificate'), this.styles)
      })
    }

    public clear () {
      this.tripNumber = null
      this.reset(this.activity)
    }

    public reset (obj: any) {
      for (const key in obj) {
        obj[key] = null
      }
    }
}

